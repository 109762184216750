<template>
    <section id="traiteRhapsody" class="page-traite" :key="componentKey">
        <div v-if="!isMobile()" >
            <div class="d-desk">
                <div class="opt-ctn-1 ctn-direction">
                    <div class="opt-ctn-bloc">
                        <h1 style="display: none;">Rhapsody</h1>
                        <h2 class="title-h2 text-focus-in">{{$t('titleTraiteRhapsody')}}</h2>
                        <p class="txt-turquoise">{{$t('dataTraite')['0']}}</p>
                        <p>{{$t('dataTraite')['1']}} <strong>{{$t('dataTraite')['2']}}</strong> {{$t('dataTraite')['3']}}</p>
                        <p>
                            <strong>{{$t('dataTraite')['4']}}</strong> {{$t('dataTraite')['5']}}
                        </p>
                    </div>
                </div>

                <div class="opt-ctn-2 txt-turquoise ctn-direction">
                    <div class="opt-ctn-bloc">
                        <div>{{$t('dataTraite')['6']}}</div>
                        <div>{{$t('dataTraite')['7']}}</div>
                    </div>
                </div>

                <div class="opt-slide">
                    <div class="slide-lane">
                        <div class="slide-lane-logo" v-for="logo in logos" :key="logo.id">
                            <figure><img :src='logo.src' :alt="logo.alt"></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <TraiteRhapsodyMobile/>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'traiteRhapsody',
        props: {
            msg: String,
        },
        components:{
            TraiteRhapsodyMobile: () => import('@/components/TraiteRhapsodyMobile.vue'),
        },
        metaInfo() {
            return { 
                title: "Rhapsody - Traité de Rhapsody",
                meta: [
                    { name: 'description', content:  'Le savoir-faire est au coeur du projet qui se veut engagé et durable. 7 certifications et labels qui garantissent un niveau de performance exceptionnel'},
                    { property: 'og:title', content: "Rhapsody - Traité de Rhapsody"},
                    { property: 'og:site_name', content: 'Rhapsody'},
                    { property: 'og:type', content: 'website'},    
                ]
            }
        },

        data: () => ({
            componentKey: 0,
            observer: null,
            logos:[
                {id:1, src: "/images/traite_rhapsody/wired.svg", ref:'wired', alt:'logo-wired'},
                {id:2, src: "/images/traite_rhapsody/breeam.svg", ref:'breeam', alt:'logo-breeam'},
                {id:3, src: "/images/traite_rhapsody/bbc.svg", ref:'bbc', alt:'logo-bbcEffinergie'},
                {id:4, src: "/images/traite_rhapsody/biodiuerCity.svg", ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {id:5, src: "/images/traite_rhapsody/ec.svg", ref:'ec', alt:'logo-ec'},
                {id:6, src: "/images/traite_rhapsody/hqe.svg", ref:'hqe', alt:'logo-hqe'},
                {id:7, src: "/images/traite_rhapsody/well.svg", ref:'well', alt:'logo-well'},
                {id:8, src: "/images/traite_rhapsody/wired.svg", ref:'wired', alt:'logo-wired'},
                {id:9, src: "/images/traite_rhapsody/breeam.svg", ref:'breeam', alt:'logo-breeam'},
                {id:10, src: "/images/traite_rhapsody/bbc.svg", ref:'bbc', alt:'logo-bbcEffinergie'},
                {id:11, src: "/images/traite_rhapsody/biodiuerCity.svg", ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {id:12, src: "/images/traite_rhapsody/ec.svg", ref:'ec', alt:'logo-ec'},
                {id:13, src: "/images/traite_rhapsody/hqe.svg", ref:'hqe', alt:'logo-hqe'},
                {id:14, src: "/images/traite_rhapsody/well.svg", ref:'well', alt:'logo-well'},
                {id:15, src: "/images/traite_rhapsody/wired.svg", ref:'wired', alt:'logo-wired'},
                {id:16, src: "/images/traite_rhapsody/breeam.svg", ref:'breeam', alt:'logo-breeam'},
                {id:17, src: "/images/traite_rhapsody/bbc.svg", ref:'bbc', alt:'logo-bbcEffinergie'},
                {id:18, src: "/images/traite_rhapsody/biodiuerCity.svg", ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {id:19, src: "/images/traite_rhapsody/ec.svg", ref:'ec', alt:'logo-ec'},
                {id:20, src: "/images/traite_rhapsody/hqe.svg", ref:'hqe', alt:'logo-hqe'},
                {id:21, src: "/images/traite_rhapsody/well.svg", ref:'well', alt:'logo-well'},
            ],
        }),
        
        mounted(){
            document.body.classList.remove('removeHiddenOverflow');
        },

        created(){
            window.addEventListener('resize', this.handleresize);
        },

        destroyed () {
            window.removeEventListener('resize', this.handleresize);
        },

        methods:{
            isMobile() {
                if( screen.width <= 1200 ) {
                    return true;
                }
                else {
                    return false;
                }
            },
            handleresize(){
                this.componentKey =+ 1;
                this.isMobile();
            },
        }
    };
</script>